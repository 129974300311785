import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Promote your destination, activities, or local service on Trailguide to reach
  your customers through the app, website and the Trailguide network.
    </p>
    <br />
    <br />
    <h4>{`Official destination`}</h4>
    <p>{`Be visible on Trailguide as an official destination in the app and on our website.
Trailguide is used by more than 30.000 users per month by people that are
dedicated outdoor enthusiasts and like to travel.`}</p>
    <br />
    <p>{`Get your destination page on Trailguide and
present your activities with your identity.`}</p>
    <Image src="solutions/destinationlogo.png" className="my-12 w-1/2 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Content experts & marketing campaigns`}</h4>
    <p>{`We are experts in outdoor sports and can create high quality content for you.
Promote your destination through the Trailguide network and reach new
customers. `}<Link to="/solutions_old/contentservices" mdxType="Link">{`Read more here.`}</Link></p>
    <br />
    <p>{`Get your own destination landing page, articles, and great pictures. Get
promoted through our channels including the Trailguide app itself, our websites,
newsletter, and our social media channels with dedicated followers.`}</p>
    <Image src="solutions/gol.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Local service`}</h4>
    <p>{`Planning the holiday and the tours, it is very valuable to know where to stay
over night, where to rent or fix a bike, where to find the right gear in a
sport shop, book guided tours, or to find the best place for a drink and a
good meal after the tour.`}</p>
    <br />
    <p>{`Promote your local service on Trailguide and get more customers. Your local
service will be visible on the map as a point of interest and visible in all
activity pages in a radius of 20km around your location.`}</p>
    <Image src="solutions/localservice.png" className="my-12 w-1/2 mx-auto" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      